import React from "react";
import "./Administration.css"; // Create this CSS file
import img from '../assets/images/img2.jpg';
import quoteImage from '../assets/images/story-quote.png';
const ChairmanMessage = () => {
  return (
  <>
    <div className="chairman-message-container">
      <div className="chairman-message-content">
        <div className="chairman-image">
          <img src={img} alt="Chairman" />
        </div>
        <div className="chairman-text">
        <img src={quoteImage} alt="quote" /><br /><br />
          <p className="quote">
            It is our vision here at AF that we are able to, both now and in the
            future, demonstrably contribute in a socially, ethically, and
            environmentally-responsible way to the development of a society
            where the needs of all are met. And, in a manner that does not
            compromise the ability of those that come after us, to meet the
            needs of their own, future generations.
          </p>
          <p className="author">
            <strong>MR. SHYAM B HARNE</strong>
            <br />
            President, AF Group
          </p>
        </div>
      </div>
    </div>

    <div className="chairman-message-container">
      <div className="chairman-message-content">
        <div className="chairman-image">
          <img src={img} alt="Chairman" />
        </div>
        <div className="chairman-text">
        <img src={quoteImage} alt="quote" /><br /><br />
          <p className="quote">
            Our journey towards women's economic empowerment is rooted in the understanding that when women prosper, societies flourish. It is about acknowledging the inherent strength, resilience, and wisdom that women bring to every facet of life. When we empower a woman economically, we are not only uplifting an individual but laying the foundation for an entire community to rise.
          </p>
          <p className="author">
            <strong>MRS. SHRADDHA S HARNE</strong>
            <br />
            Secretary, AF Group
          </p>
        </div>
      </div>
    </div>

    <div className="chairman-message-container">
      <div className="chairman-message-content">
        <div className="chairman-image">
          <img src={img} alt="Chairman" />
        </div>
        <div className="chairman-text">
        <img src={quoteImage} alt="quote" /> <br /><br />
          <p className="quote">
            We partner with our students in learning and strive to deepen their knowledge, provide rich and innovative learning experiences, while also urging our students to step out of their comfort zones to attempt new challenges. Are. Our students graduate with the skills, mindsets and attributes that will best prepare them for success, let us give our best and make this institution a modern temple of learning through our hard work, dedication and devotion. Best wishes to you for the future world...! They are the future leaders and role models of the society.
          </p>
          <p className="author">
            <strong>MRS. YOGESHWARI G. PALODE</strong>
            <br />
            Principal, B. D. Harne English Medium School & Jr. College
          </p>
        </div>
      </div>
    </div>

  </>
  );
};

export default ChairmanMessage;

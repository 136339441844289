import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './EventTable.css';

// Sample event data
const events = [
  { id: 1, name: 'Quiz Competition', date: '15-10-2024', year: '2024-2025' },
  { id: 2, name: 'Science Olympiad', date: '10-11-2024', year: '2024-2025' },
  { id: 3, name: 'Abacus Competition', date: '05-12-2024', year: '2024-2025' },
  { id: 4, name: 'Maths Olympiad', date: '01-10-2023', year: '2023-2024' },
  { id: 5, name: 'Spelling Bee', date: '20-11-2023', year: '2023-2024' },
];

// Year filter buttons
const yearFilters = [
  { label: 'All Events', value: 'all' },
  { label: '2024-2025', value: '2024-2025' },
  { label: '2023-2024', value: '2023-2024' },
];

const EventTable = () => {
  const [selectedYear, setSelectedYear] = useState('all');

  // Filter events based on selected year
  const filteredEvents = selectedYear === 'all'
    ? events
    : events.filter((event) => event.year === selectedYear);

  return (
    <div className="event-table-container">
      <h2> Events </h2>

      {/* Year Filter Buttons */}
      <div className="filter-buttons">
        {yearFilters.map((filter) => (
          <button
            key={filter.value}
            className={`filter-button ${selectedYear === filter.value ? 'active' : ''}`}
            onClick={() => setSelectedYear(filter.value)}
          >
            {filter.label}
          </button>
        ))}
      </div>

      <table className="event-table">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Event</th>
            <th>Date</th>
            <th>Viewing</th>
          </tr>
        </thead>
        <tbody>
          {filteredEvents.map((event, index) => (
            <tr key={event.id}>
              <td>{index + 1}</td>
              <td>{event.name}</td>
              <td>{event.date}</td>
              <td>
                <Link to={`/event/${event.id}`} className="view-button">
                  View
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EventTable;

import React from 'react';
import donatebanner from '../assets/images/Donate.jpg';
import scannerImage from '../assets/images/Payment.png';
import './Donate.css';

const Donate = () => {
  return (
    <div className="donate-container">
      {/* Banner Section */}
      <div className="donation-banner">
        <img 
          src={donatebanner} // Replace with actual image path
          alt="Donation Banner"
          className="donation-banner-image"
        />
        {/* <div className="donation-banner-text">
          <h1>DONATION</h1>
          <p>We thank you for considering donating to Aaradhya Foundation and its initiatives.</p>
        </div> */}
      </div>

      {/* Donate Message */}
      <div className="donate-message-section">
        <h2>DONATE FOR AN EMPOWERD FUTURE</h2>
        <p>
          We thank you for considering donating to Aaradhya Foundation. Your support can make a difference in young lives by building a sustainable, more equitable, and inclusive community for the country's youth.
        </p>
        <p>
        Aaradhya Foundation (AF) was Established in 2020 under public charitable trust act 1950 with a vision of Empowerment through Education, working in three areas - Adolescent Girls, Widows and Destitute Women.
        </p>
        <p>
          Visit our website to learn more about our programs and initiatives.
        </p>
        <h5>Tax Exemption Details</h5>
        <p>
          All donations made to ‘Aaradhya Foundation’ are eligible for Tax deduction under Section 80G of the Income Tax Act, 1961.
        </p>
      </div>
          <br />
      {/* Form Section */}
      <div className="dfs"> 
      {/* <h3>DONATE HERE</h3> */}
      <div className="donation-form-section">
        
          <div className="donation-scanner">
            <h5> Donate Using QR Sacnner</h5>
            <img src={scannerImage} alt="Scanner" />
          </div>

          <div className="donation-details">
            <h5> Donate Using Account Details</h5>
            <p> <strong> Account Details : </strong><br />
            <strong>  PAYEE </strong> : AARADHYA FOUNDATION <br />
            <strong>  BANK </strong> : HDFC BANK LTD. <br />
            <strong>  BRANCH </strong> : DOMBIVLI (WEST) <br />
            <strong>  A/c No. </strong> : 59208655555555 <br />
            <strong>  RTGS / NEFT IFSC </strong> : HDFC0005411  <br />
            
              <br /><br />
              <strong> Account Details : </strong> <br />
              <strong>  PAYEE </strong> : AARADHYA FOUNDATION <br />
              <strong>  BANK </strong> : HDFC BANK LTD. <br />
              <strong>  BRANCH </strong> : DOMBIVLI (WEST) <br />
              <strong>  A/c No. </strong> : 99986555555555 <br />
              <strong>  RTGS / NEFT IFSC </strong> : HDFC0005411  <br />
            </p>
          </div>

        </div>
        {/* <div className="donation-buttons">
        <a href=" " className="donation-button blue-button">DONATE NOW</a> */}
          {/* <a href=" " className="donation-button blue-button">Indian Citizens & Non-Resident Indians (NRI)</a>
          <a href=" " className="donation-button white-button">Corporate Donors and Foreign Nationals</a> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Donate;

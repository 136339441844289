import React from 'react';

const ProjectBrochure = () => {
  return (
    <div>
      <h2>Project Brochure</h2>
      <p>Information about the project brochure will be displayed here.</p>
    </div>
  );
};

export default ProjectBrochure;

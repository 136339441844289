import React from 'react';
import aboutImage from '../assets/images/WeThink_1.jpg';
import aboutImage1 from '../assets/images/WeThink_2.jpg';
const AboutUs = () => {
  return (
    <section className="about-us" id="about">
    <h2>WE THINK</h2>
      <div className="about-us-content">

        <div className="image-content">
          <img src={aboutImage} alt="About Us" />
          <img src={aboutImage1} alt="About Us1" />
        </div>

        <div className="text-content">
          
          <p>
          <strong> EDUCATION</strong> is both the means as well as the end to a better life: the means because it empowers an individual to earn his/her livelihood and the end because it increases one's awareness on a range of issues - from healthcare to appropriate social behavior to understanding one's rights - and in the process help him/her evolve as a better citizen. Education is the most effective tool which helps to build a strong foundation; enabling them to free themselves from the vicious cycle of ignorance, poverty and disease.
          </p>
          <p>
          We (AF) focuses on developing the potential of Child, women and girls to drive long lasting equitable changes. We strategically emphasize on promoting quality healthcare, inclusive education, gender equitable and sustainable livelihood opportunities and disaster relief and preparedness.
          </p>
          <p>
          We are dedicated to empowering women to achieve their full economic potential by inspiring both women and men to become advocates, change makers and leaders in their community. We equip them with resources, opportunities and a global platform that facilitates networking, learning and sharing of experiences.
          </p>
        </div>
        
      </div>
    </section>
  );
};

export default AboutUs;

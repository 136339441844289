import React, { useState, useEffect } from 'react';
import './BackToTop.css'; // Import the CSS

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div>
      <a
        href="#"
        className={`back-to-top d-flex align-items-center justify-content-center ${isVisible ? 'active' : ''}`}
        onClick={scrollToTop}
      >
        <svg
          width="24"
          height="24"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M12 4l-8 8h6v8h4v-8h6z" />
        </svg>
        <i className="fa fa-arrow-up"></i>
      </a>

      
        {/* <i className="bi bi-arrow-up-short"></i> */}
    
    </div>
  );
};

export default BackToTop;

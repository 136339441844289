import React from 'react';

import StudentA from '../assets/images/StudentA.png';
import StudentB from '../assets/images/StudentB.png';
import StudentC from '../assets/images/StudentC.webp';

const Testimonials = () => {
  return (
    <section className="testimonials" id="testimonials">
      <h2>What do our students say? </h2>
      <div className="testimonials-list">
        <div className="testimonial">
          <img src={StudentA} alt="Student A" className="testimonial-img" />
          <p>"This institute helped me achieve my dream job!"</p>
          <h4>Student A</h4>
        </div>
        <div className="testimonial">
          <img src={StudentB} alt="Student B" className="testimonial-img" />
          <p>"Amazing faculty and a friendly environment"</p>
          <h4>Student B</h4>
        </div>
        <div className="testimonial">
          <img src={StudentC} alt="Student C" className="testimonial-img" />
          <p>"Best School and my fundamentals got strong"</p>
          <h4>Student C</h4>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
